import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

import Column from 'src/components/Column';
import Header from 'src/components/Header';

import ImageCVRTRow from 'src/containers/ImageCVRTRow';
import {withSEO} from 'src/hoc/SEO';
import { pages } from 'src/config';

import css from './index.module.scss';
import Img from 'gatsby-image';


const FAQsPage = () => {
  return (
    <main>
      <StaticQuery
        query={graphql`
          query {
            faqs: file(relativePath: { eq: "images/faqs/faqs.png" }) {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            logo: file(relativePath: { eq: "images/test-centre/logo.png" }) {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
            <Column direction='cc'>
              <Link to='/' className={css.logoImg}>
                <Img fluid={data.logo.childImageSharp.fluid}/>
              </Link>
              <ImageCVRTRow image={data.faqs.childImageSharp.fluid}/>
            </Column>
        )}
      />
      
      <Column className={css.faqs} direction='cc'>
        <Header primaryText="Frequently Asked Question's"/>
        
        <Column className={css.questionContainer} direction='cl'>
          <h4 className={css.question}>What is required for the Commercial Vehicle Test?</h4>
          <ol>
            <li>
              The person presenting the vehicle for its test is now required to produce&nbsp;
              <a
                href='https://www.cvrt.ie/en/Certificate-of-Roadworthiness/Pages/Requirement-for-Presenter-ID.aspx'
                target='_blank'
                rel='noopener noreferrer'
              >
                Presenter I.D.
              </a> in the form of a valid Driving Licence or Passport at the test centre
            </li>
            <li>The vehicle should be washed and cleaned</li>
            <li>The appropriate fee – see <Link to='/test-centre#pricing'>here</Link> for pricing</li>
          </ol>
        </Column>
  
        <Column className={css.questionContainer} direction='cl'>
          <h4 className={css.question}>How often is a Commercial Vehicle test required?</h4>
          <p>All Commercial vehicles now require the CVR Test (formally VTN and DOE Test) to be carried out every year.</p>
        </Column>
  
        <Column className={css.questionContainer} direction='cl'>
          <h4 className={css.question}>What happens if my vehicle fails the test?</h4>
          <p>If your vehicle fails, you’ll need to carry out a retest on the vehicle with us on the failed items within 21 days.</p>
          <h4>Cost & procedure of a retest</h4>
          <p>If you return for the re-test within 21 days and your vehicle has travelled fewer than 4,000 kilometres
            since it was tested:</p>
          <ul>
            <li>The vehicle does not have to repeat the full test; rather, it is tested only on the failure points from the first test</li>
            <li>A visual re-test is free, but a retest that requires a test lane has a fee starting from €35.43 (check
              our price list <Link to='/test-centre#pricing'>here</Link>)
            </li>
            <li>You should repair vehicle fail items immediately and present the vehicle for re-test as soon as possible</li>
          </ul>
        </Column>
  
        <Column className={css.questionContainer} direction='cl'>
          <h4 className={css.question}>How do I make a booking?</h4>
          <p>
            You can book online – see <Link to='/test-centre/bookings'>here...</Link> or you can call us
            on <a href='tel:+353-22-21614'>022 21614</a> to make a booking over the phone.
          </p>
        </Column>
  
        <Column className={css.questionContainer} direction='cl'>
          <h4 className={css.question}>What times can I book a test for?</h4>
          <p>
            Monday to Friday: 8.30am to 5.30pm (Closed for lunch 1pm to 2pm)<br/>
            Saturday: 9am to 1pm (closed Saturday of Bank Holiday weekends)<br/>
            Sunday: Closed
          </p>
        </Column>
        
      </Column>
    </main>
  );
};

export default withSEO({
  title: pages.faqs.title
})(FAQsPage);
